import React from 'react';
import Gyrokinesis from '../components/Gyrokinesis';
import Gyrotonic from '../components/Gyrotonic';
import { footerHolder } from './Layout.module.scss';
import { Link } from 'gatsby';
import ContactDetails from '../components/ContactDetails';

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="footerNavs">
        <ContactDetails />
        <nav className="classes">
          <ul>
            <li>
              <Link to="/classes/gyrotonic">
                <Gyrotonic />
              </Link>
            </li>
            <li>
              <Link to="/classes/gyrokinesis">
                <Gyrokinesis />
              </Link>
            </li>
            <li>
              <Link to="/classes/body-rolling">Body Rolling</Link>
            </li>
            <li>
              <Link to="/classes/breath-fit">Breath Fit</Link>
            </li>
            <li>
              <Link to="/classes/face-ball">Face Ball</Link>
            </li>
          </ul>
        </nav>
        <nav className="links">
          <ul>
            {/* <li>
              <Link to="/about" className="About Dione">
                About me
              </Link>
            </li> */}
            <li>
              <Link to="/contact" title="Contact Dione Studios">
                Get in touch
              </Link>
            </li>
            <li>
              <Link to="/book-a-session" title="Book a Session at Dione Studios" className="book">
                Book a session
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="gyrotonicDisclaimer">
        <p>
          <Gyrotonic /> & <Gyrokinesis /> are registered trademarks of Gyrotonic Sales Corp and are used with their permission.
        </p>
      </div>
      <div className="footerBottom">
        <div className="copywrite">
          <p>&copy; {new Date().getFullYear()} Dione Studios</p>
        </div>
        <div className="credit">
          <p>
            Content by{' '}
            <a href="https://cornishmarketing.co.uk/" target="_blank" rel="noreferrer" title="Cornish Marketing Company">
              Cornish Marketing Consultancy
            </a>
            . Site built by{' '}
            <a href="mailto:ben@lookupstudios.co.uk" title="Email Look Up!">
              Look Up!
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
