import React from 'react';
import { headerHolder } from './Layout.module.scss';
import Nav from './Nav';
import { Link } from 'gatsby';
import Logo from '../components/Logo';

export default function Header({ location }) {
  return (
    <div className={headerHolder}>
      <Link to="/">
        <Logo location={location} />
      </Link>
      <Nav />
    </div>
  );
}
