import React, { useState } from "react";
import { navHolder } from "./Layout.module.scss";
import Gyrotonic from "../components/Gyrotonic";
import Gyrokinesis from "../components/Gyrokinesis";
import { Link } from "gatsby";
import { debounce } from "lodash";
import { motion, AnimatePresence } from "framer-motion";
import { useIsSmallScreen } from "../hooks/useWindowSizes";
import "../styles/hamburgers.min.css";

const navVariants = {
  initial: {
    opacity: 0,
    x: "100%",
  },
  visible: {
    opacity: 1,
    x: "0",
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  hidden: {
    opacity: 0,
    x: "100%",

    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const ulVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
      ease: "easeOut",
      when: "beforeChildren",
      staggerChildren: 0.04,
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      staggerChildren: 0.02,
      staggerDirection: 1,
    },
  },
};

const liVariants = {
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,

    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },

  exit: {
    opacity: 0,
  },
};

export default function Nav() {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const isSmallScreen = useIsSmallScreen(880);

  const [navOpen, setNavOpen] = useState(false);

  const debouncedHandleMouseEnter = debounce(() => {
    setShowSubMenu(true);
  }, 100);

  const debouncedHandleMouseLeave = debounce(() => {
    debouncedHandleMouseEnter.cancel();
    setShowSubMenu(false);
  }, 50);

  const handleMouseEnterSubMenu = () => {
    debouncedHandleMouseLeave.cancel();
  };

  return (
    <nav className={navHolder}>
      {isSmallScreen && (
        <div className="burger">
          <button
            className={`hamburger hamburger--slider ${
              navOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setNavOpen(!navOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
      )}

      <motion.div
        className={`ulHolder ${isSmallScreen ? "smallScreenNav" : ""}`}
        variants={navVariants}
        initial="initial"
        animate={`${
          isSmallScreen ? (navOpen ? "visible" : "hidden") : "visible"
        }`}
      >
        <ul className={`mainMenu ${navOpen ? "open" : ""} `}>
          <li className={`hasSubMenu ${showSubMenu ? "expanded" : ""}`}>
            <Link
              to="/classes"
              onMouseEnter={() => {
                if (!isSmallScreen) debouncedHandleMouseEnter();
              }}
              onMouseLeave={() => {
                if (!isSmallScreen) debouncedHandleMouseLeave();
              }}
              onClick={() => setNavOpen(false)}
              activeClassName="active"
            >
              Classes
            </Link>
            <AnimatePresence>
              {showSubMenu && (
                <motion.ul
                  key={"ul"}
                  className="subMenu"
                  onMouseEnter={handleMouseEnterSubMenu}
                  onMouseLeave={debouncedHandleMouseLeave}
                  variants={ulVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <motion.li variants={liVariants}>
                    <Link to="/classes/gyrotonic">
                      <Gyrotonic />
                    </Link>
                  </motion.li>
                  <motion.li variants={liVariants}>
                    <Link to="/classes/gyrokinesis">
                      <Gyrokinesis />
                    </Link>
                  </motion.li>
                  <motion.li variants={liVariants}>
                    <Link to="/classes/body-rolling">Body Rolling</Link>
                  </motion.li>
                  <motion.li variants={liVariants}>
                    <Link to="/classes/breath-fit">Breath Fit</Link>
                  </motion.li>
                  <motion.li variants={liVariants}>
                    <Link to="/classes/face-ball">Face Ball</Link>
                  </motion.li>
                </motion.ul>
              )}
            </AnimatePresence>
          </li>
          {/* <li>
            <Link to="/about" onClick={() => setNavOpen(false)} activeClassName="active">
              About me
            </Link>
          </li> */}
          <li>
            <Link
              to="/courses"
              onClick={() => setNavOpen(false)}
              activeClassName="active"
            >
              Teacher Training
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              onClick={() => setNavOpen(false)}
              activeClassName="active"
            >
              Get in touch
            </Link>
          </li>
          <li
            className="book-a-session"
            onClick={() => setNavOpen(false)}
            activeClassName="active"
          >
            <Link to="/book-a-session">Book a session</Link>
          </li>
        </ul>
      </motion.div>
    </nav>
  );
}
