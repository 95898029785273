import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/normalize.css';
import '../styles/styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import hydrateImages from '../utils/hydrateImages';

import isBrowser from '../utils/isBrowser';

const mainHolder = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export default function Layout({ children, location }) {
  return (
    <div className={`pageHolder`}>
      <Header location={location} />
      <AnimatePresence
        mode="wait"
        onExitComplete={() => {
          setTimeout(() => hydrateImages(), 100);
          //scroll to top of page
          if (isBrowser()) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }}>
        <motion.main key={children?.props?.path} variants={mainHolder} initial="initial" animate="animate" exit="exit">
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </div>
  );
}
