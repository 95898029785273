exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-a-session-js": () => import("./../../../src/pages/book-a-session.js" /* webpackChunkName: "component---src-pages-book-a-session-js" */),
  "component---src-pages-classes-body-rolling-js": () => import("./../../../src/pages/classes/body-rolling.js" /* webpackChunkName: "component---src-pages-classes-body-rolling-js" */),
  "component---src-pages-classes-breath-fit-js": () => import("./../../../src/pages/classes/breath-fit.js" /* webpackChunkName: "component---src-pages-classes-breath-fit-js" */),
  "component---src-pages-classes-face-ball-js": () => import("./../../../src/pages/classes/face-ball.js" /* webpackChunkName: "component---src-pages-classes-face-ball-js" */),
  "component---src-pages-classes-gyrokinesis-js": () => import("./../../../src/pages/classes/gyrokinesis.js" /* webpackChunkName: "component---src-pages-classes-gyrokinesis-js" */),
  "component---src-pages-classes-gyrotonic-js": () => import("./../../../src/pages/classes/gyrotonic.js" /* webpackChunkName: "component---src-pages-classes-gyrotonic-js" */),
  "component---src-pages-classes-index-js": () => import("./../../../src/pages/classes/index.js" /* webpackChunkName: "component---src-pages-classes-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/course-template.js" /* webpackChunkName: "component---src-templates-course-template-js" */)
}

