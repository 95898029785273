const React = require('react');
const Layout = require('./src/layout/Layout').default;
// const ContextsProvider = require('./src/components/SiteContexts').default;

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// exports.wrapRootElement = ({ element }) => {
//   return <ContextsProvider>{element}</ContextsProvider>;
// };

exports.shouldUpdateScroll = () => false;
